import '../styles';
import './portChoice.js';
import './submitOnSelect.js';
import './fullNameSearch.js';
import './countryCodeSelect.js';
import './personDetails.js';
import './updateScreen.js';
import './common.js';
import './emailValidation.js';
import './preventDoubleClick.js';
import { initAll } from 'govuk-frontend/dist/govuk/all.bundle.js';

initAll();
